export default {
    "Voice v3": {
        type: "Voice v3",
        version: "V3",
        options: {
            "callflow1": {
                category: "Call Flow",
                label: "Proper Introduction",
                message: `Team Member prepared to answer the call upon arrival (5 seconds or less), provides "Ring Community Support" branded opening and offers to help.`
            },
            "callflow2": {
                category: "Call Flow",
                label: "Probe to Understand Neighbor's Needs and Perform Proper Troubleshooting",
                message: `Asking effective probing questions before troubleshooting begins to identify root cause of any issues and assist in providing correct resolution.  Team Member followed proper troubleshooting steps based on the reason for the Neighbor calling in.`
            },
            "callflow3": {
                category: "Call Flow",
                label: "Sets Proper Agenda, Confirms Resolution and Set Future Expectations",
                message: `Advise the Neighbor or what we will be doing together to resolve the issue to put our Neighbor at ease and avoid frustration by ensuring their understanding.  The Team Member and Neighbor test the product(s) and agree to a resolution or next steps for a resolution.  If necessary set any future expectations.`
            },
            "callflow4": {
                category: "Call Flow",
                label: "Followed Proper Hold Procedures",
                message: `We ask the Neighbor permission prior to placing them on hold, advise of reason for hold and set proper expectations on the length of time.  Team Member checks in with the Neighbor every 120 seconds of the hold and thanks the Neighbor for holding after hold is finished.`
            },
            "callflow5": {
                category: "Call Flow",
                label: "Followed Proper Process per Policy",
                message: `Team Member follows all documented/written policies and procedures per Ring Central, Ring Assist, and/or any written Ring Communication within the interaction.`
            },
            "callflow6": {
                category: "Call Flow",
                label: "Handling/Navigation Efficiency",
                message: `During the interaction the Team Member utilizes resources, tools and skills to assist in an efficient manner.`
            },
            "callflow7": {
                category: "Call Flow",
                label: "Insight Panel and Note Completion",
                message: `Are all fields in the Insight Panel completed and the appropriate issue code was selected that correlates with the Neighbor's original contact reason.  
                Subject line is updated to correspond with the initial call reason and notes are relevant to the call as well as reflect next steps taken.`
            },
            "callflow8": {
                category: "Call Flow",
                label: "Proper Closing",
                message: `Probe for further assistance in order to confirm Neighbor satisfaction with the outcome and positively thank the Neighbor for calling Ring Community Support. 
                When applicable, offer to opt the Neighbor in for SMS if they have not already.`
            },
            "callflow9": {
                category: "Call Flow",
                label: "Brand Loyalty",
                message: `Team member displays brand loyalty during interaction by promoting company and products with positive positioning.`
            },
            "softskill1": {
                category: "Soft Skills",
                label: "Confidence",
                message: `Team Member is confident in delivery providing solid and smooth responses and solutions.`
            },
            "softskill2": {
                category: "Soft Skills",
                label: "Effective Transitions",
                message: `Dead air is minimal (less than 10 seconds unless brought on by the Neighbor) and the Team Member keeps conversation moving with transitional phrases.`
            },
            "softskill3": {
                category: "Soft Skills",
                label: "Call Control",
                message: `Team Member is proficient in managing the interaction overall and guiding the outcome.  
                The pace of the interaction is acceptable to the Neighbor who does not need to ask for anything to be repeated or reexplained as a result.  `
            },
            "softskill4": {
                category: "Soft Skills",
                label: "Takes Ownership and Displays Willingness to Assist",
                message: `Team Member assures the Neighbor that they will help and take responsibility for a resolution.  `
            },
            "softskill5": {
                category: "Soft Skills",
                label: "Active Listening Skills",
                message: `Active listening skills are utilized to ensure that there are no conversation collisions/overtalking and the Neighbor does not have to repeat any information.`
            },
            "softskill6": {
                category: "Soft Skills",
                label: "Rapport and Engagement",
                message: `Team Member sounds engaged, interested, energetic, upbeat and conversational when speaking with the Neighbor.   The Team Member builds rapport/relationship via inquires such as "how are you today?" or making small talk in order to keep the conversation in motion and engaged.  
                When speaking with the Neighbor the Team Member personalizes the interaction by utilizing the Neighbor's name when applicable.  `
            },
            "neighborimpacting1": {
                category: "Neighbor Impacting",
                label: "N/A | Does not apply to review",
                message: `Does not apply`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Combative Call/Argues with Neighbor",
                message: `Team Member argues with our Neighbor or becomes combative/threatening.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Blatant Call/Work/Survey Avoidance",
                message: `Team member displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties.  
                Please refer to the behavior escalation grid for additional detail: https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Sharing Internal Documents:",
                message: `DO NOT send out any internal documents/screenshots of internal tools (ie. Admin Panel, Kustomer, Stripe, etc.) to a Neighbor
                (https://support.ring.com/hc/en-us)`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Did not use outbound call verbiage when required.",
                message: `https://central.ring.com/support_article/call-flow-and-callback-verbiage-guide/`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Did not use Replacement Verbiage",
                message: `https://central.ring.com/logistics/how-to-process-a-replacement/?hilite=%27replacement%27%2C%27verbiage%27`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Follow Up expectations set were not completed",
                message: `Did the Team Member set expectations with the Neighbor and not follow through (broken promises)? 
                i.e. Promising to send a follow up email and not sending one.
                Promising the Neighbor that your Supervisor will call them back and not following up with your Supervisor to make sure that happens.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Disclosing Personal Information",
                message: `Neighbors are to confirm shipping addresses as opposed to the Team Member releasing this type of sensitive information when sending replacements. 
                Team Members should never disclose account specific information about an account that is not in the owner's name (e.g. name of the email address on the account).`
            },
            "redflag8": {
                category: "Red Flags",
                label: "Account Verification and Device Authorization",
                message: `https://central.ring.com/support_article/account-verification-and-device-authorization/`
            },
            "redflag9": {
                category: "Red Flags",
                label: "Invalid Replacement",
                message: `The associate processed a replacement for the neighbor when it was not appropriate to do so, or used the incorrect PLC replacement code.`
            },
            "redflag10": {
                category: "Red Flags",
                label: "Safety Concern",
                message: `The associate provided incorrect support steps or information to the neighbor that may result in a safety concern, or if an associate fails to escalate a Safety or Security concern through the proper channels.`
            },
            "redflag11": {
                category: "Red Flags",
                label: "Work from Home Environment Concerns",
                message: `Work from Home Environment Concerns (Music/Animals/Children/Other People in the back ground. Excessive Noise).`
            },
            "redflag12": {
                category: "Red Flags",
                label: "Excessive Hold Times or Dead Air",
                message: `Hold times or dead air exceeding 2 minutes and 30 seconds with no check-in with the Neighbor.`
            }
        }
    }, "NSE v3": {
        type: "NSE v3",
        version: "V3",
        options: {
            "callflow1": {
                category: "Call Flow",
                label: "Proper Introduction",
                message: `Team Member was prepared to answer the call upon arrival (5 seconds or less) and followed the introduction/question guide below.
                "Thank you for contacting the Neighbor Solutions Expert team my name is ___________. Who am I speaking with today? Hello ______________.
                In order to help you out, I’ll only need a few pieces of information from you.
                •Who is the neighbor I will be speaking with?
                •What device are we troubleshooting?
                •What issue is this device having?
                Thank you again for the information. Please update the ticket with any additional notes you may have and leave the ticket open. At this time you can warm transfer the neighbor and I will take it from here. Have a great day!"`
            },
            "callflow2": {
                category: "Call Flow",
                label: "Probe to Understand Neighbor's Needs and Perform Proper Troubleshooting",
                message: `Asking effective probing questions before troubleshooting begins to identify root cause of any issues and assist in providing correct resolution.  
                Team Member followed proper troubleshooting steps based on the reason for the Neighbor calling in.`
            },
            "callflow3": {
                category: "Call Flow",
                label: "Sets Proper Agenda, Confirms Resolution and Set Future Expectations",
                message: `Advise the Neighbor or what we will be doing together to resolve the issue to put our Neighbor at ease and avoid frustration by ensuring their understanding.  
                The Team Member and Neighbor test the product(s) and agree to a resolution or next steps for a resolution.  If necessary set any future expectations.`
            },
            "callflow4": {
                category: "Call Flow",
                label: "Followed Proper Hold Procedures",
                message: `We ask the Neighbor permission prior to placing them on hold, advise of reason for hold and set proper expectations on the length of time.  
                Team Member checks in with the Neighbor every 120 seconds of the hold and thanks the Neighbor for holding after hold is finished.`
            },
            "callflow5": {
                category: "Call Flow",
                label: "Followed Proper Process per Policy",
                message: `Team Member follows all documented/written policies and procedures per Ring Central, Ring Assist, and/or any written Ring Communication within the interaction.`
            },
            "callflow6": {
                category: "Call Flow",
                label: "Handling/Navigation Efficiency",
                message: `During the interaction the Team Member utilizes resources, tools and skills to assist in an efficient manner.`
            },
            "callflow7": {
                category: "Call Flow",
                label: "Insight Panel and Note Completion",
                message: `Are all fields in the Insight Panel completed and the appropriate issue code was selected that correlates with the Neighbor's original contact reason.  
                Subject line is updated to correspond with the initial call reason and notes are relevant to the call as well as reflect next steps taken.`
            },
            "callflow8": {
                category: "Call Flow",
                label: "Proper Closing",
                message: `Probe for further assistance in order to confirm Neighbor satisfaction with the outcome and positively thank the Neighbor for calling Ring Community Support. 
                When applicable, offer to opt the Neighbor in for SMS if they have not already.`
            },
            "callflow9": {
                category: "Call Flow",
                label: "Brand Loyalty",
                message: `Team member displays brand loyalty during interaction by promoting company and products with positive positioning.`
            },
            "softskill1": {
                category: "Soft Skills",
                label: "Confidence",
                message: `Team Member is confident in delivery providing solid and smooth responses and solutions.`
            },
            "softskill2": {
                category: "Soft Skills",
                label: "Effective Transitions",
                message: `Dead air is minimal (less than 10 seconds unless brought on by the Neighbor) and the Team Member keeps conversation moving with transitional phrases.`
            },
            "softskill3": {
                category: "Soft Skills",
                label: "Call Control",
                message: `Team Member is proficient in managing the interaction overall and guiding the outcome.  
                The pace of the interaction is acceptable to the Neighbor who does not need to ask for anything to be repeated or reexplained as a result.  `
            },
            "softskill4": {
                category: "Soft Skills",
                label: "Takes Ownership and Displays Willingness to Assist",
                message: `Team Member assures the Neighbor that they will help and take responsibility for a resolution.  `
            },
            "softskill5": {
                category: "Soft Skills",
                label: "Active Listening Skills",
                message: `Active listening skills are utilized to ensure that there are no conversation collisions/overtalking and the Neighbor does not have to repeat any information.`
            },
            "softskill6": {
                category: "Soft Skills",
                label: "Rapport and Engagement",
                message: `Team Member sounds engaged, interested, energetic, upbeat and conversational when speaking with the Neighbor.   The Team Member builds rapport/relationship to keep the conversation in motion and engaged.  
                When speaking with the Neighbor the Team Member personalizes the interaction by utilizing the Neighbor's name when applicable.  `
            },
            "neighborimpacting1": {
                category: "Neighbor Impacting",
                label: "N/A | Does not apply to review",
                message: `Does not apply`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Combative Call/Argues with Neighbor",
                message: `Team Member argues with our Neighbor or becomes combative/threatening.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Blatant Call/Work/Survey Avoidance",
                message: `Team member displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties.  
                Please refer to the behavior escalation grid for additional detail: https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Sharing Internal Documents:",
                message: `DO NOT send out any internal documents/screenshots of internal tools (ie. Admin Panel, Kustomer, Stripe, etc.) to a Neighbor
                (https://support.ring.com/hc/en-us)`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Did not use outbound call verbiage when required.",
                message: `https://central.ring.com/support_article/call-flow-and-callback-verbiage-guide/`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Did not use Replacement Verbiage",
                message: `https://central.ring.com/logistics/how-to-process-a-replacement/?hilite=%27replacement%27%2C%27verbiage%27`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Follow Up expectations set were not completed",
                message: `Did the Team Member set expectations with the Neighbor and not follow through (broken promises)? 
                i.e. Promising to send a follow up email and not sending one.
                Promising the Neighbor that your Supervisor will call them back and not following up with your Supervisor to make sure that happens.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Disclosing Personal Information",
                message: `Neighbors are to confirm shipping addresses as opposed to the Team Member releasing this type of sensitive information when sending replacements. 
                Team Members should never disclose account specific information about an account that is not in the owner's name (e.g. name of the email address on the account).`
            },
            "redflag8": {
                category: "Red Flags",
                label: "Account Verification and Device Authorization",
                message: `https://central.ring.com/support_article/account-verification-and-device-authorization/`
            },
            "redflag9": {
                category: "Red Flags",
                label: "Invalid Replacement",
                message: `The associate processed a replacement for the neighbor when it was not appropriate to do so, or used the incorrect PLC replacement code.`
            },
            "redflag10": {
                category: "Red Flags",
                label: "Safety Concern",
                message: `The associate provided incorrect support steps or information to the neighbor that may result in a safety concern, or if an associate fails to escalate a Safety or Security concern through the proper channels.`
            },
            "redflag11": {
                category: "Red Flags",
                label: "Work from Home Environment Concerns",
                message: `Work from Home Environment Concerns (Music/Animals/Children/Other People in the back ground. Excessive Noise).`
            },
            "redflag12": {
                category: "Red Flags",
                label: "Excessive Hold Times or Dead Air",
                message: `Hold times or dead air exceeding 2 minutes and 30 seconds with no check-in with the Neighbor.`
            }
        }
    }, "Chat v4": {
        type: "Chat v4",
        version: "V4",
        options: {
            "callflow1": {
                category: "Chat Flow",
                label: "Acknowledgement",
                message: `The associate greets the neighbor and acknowledges their chat within the first 60 seconds. The associate uses a proper greeting and introduction based on the current or past interactions, and restates the reason for the neighbor's contact.`
            },
            "callflow2": {
                category: "Chat Flow",
                label: "Review and Discover",
                message: `The associate reviews any previous case history or transcripts/summaries before the conversation to avoid asking redundant or unnecessary questions. The associate asks effective questions to identify the root cause of the problem and remains present and engaged in the conversation to ensure understanding without requiring the neighbor to repeat information.`
            },
            "callflow3": {
                category: "Chat Flow",
                label: "Ownership and Willingness to Assist",
                message: `The associate assures the neighbor that they will help. They take responsibility for finding a resolution and restate the issue or reassure the neighbor that they understand the situation. The associate uses empathy when necessary. When conversing with the neighbor, the associate should use the neighbor's name when appropriate.`
            },
            "callflow4": {
                category: "Chat Flow",
                label: "Proper Agenda",
                message: `The associate informs the neighbor about the steps that will be taken as they work together to find a resolution. This helps put the neighbor at ease and prevent frustration by ensuring they understand the process.`
            },
            "callflow5": {
                category: "Chat Flow",
                label: "Provides Appropriate Support/Resolution Steps",
                message: `The associate navigated and provided the necessary support and guidance using the available resources in Shelf. The associate used relevant and appropriate quick responses when applicable.`
            },
            "callflow6": {
                category: "Chat Flow",
                label: "Confirms Resolution and Proper Closing",
                message: `The associate and neighbor confirm that the issue has been resolved or agree on the next steps to find a solution. This includes discussing any expectations for both parties going forward.
                <br/><br/>The associate concludes the interaction by asking the neighbor if they need any further assistance. The associate also provides any self-service options that were not previously mentioned, and thanks the neighbor.`
            },
            "callflow7": {
                category: "Chat Flow",
                label: "Dispositions and Documentation",
                message: `The associate accurately dispositioned all issues brought up in the conversation, including the device/service option(s), contact reason, and reason details, and left notes accurate to what transpired during the contact.`
            },
            "callflow8": {
                category: "Chat Flow",
                label: "Followed Proper Process per Policy",
                message: `The associate followed all documented policies and procedures from the company.`
            },
            "softskill1": {
                category: "Soft Skills",
                label: "Chat Control",
                message: `The associate demonstrates control of the chat by setting a confident, friendly tone and pace to the interaction, maintaining focus on the neighbor's needs throughout the conversation, and balancing empathy with a solution-oriented approach.`
            },
            "softskill2": {
                category: "Soft Skills",
                label: "Followed Proper Hold Procedures",
                message: `The associate uses an approved quick response to inform the customer they will be placed on hold, including the reason and expected duration. The associate then checks in with the customer every 2 minutes during the hold and thanks them once the hold is finished.`
            },
            "softskill3": {
                category: "Soft Skills",
                label: "Avoiding Apologetic and Frustrating Language",
                message: `The associate avoids using apologetic language such as "apologize", "sorry", or acknowledging the situation as "frustrating", except when using approved Quick Responses. Otherwise, the associate uses neutral, solution-focused language to keep the interaction positive and constructive.`
            },
            "softskill4": {
                category: "Soft Skills",
                label: "Proper Grammar, Punctuation, Spelling",
                message: `The associate has two or fewer combined errors in grammar, spelling, or punctuation.`
            },
            "softskill5": {
                category: "Soft Skills",
                label: "Brand Loyalty",
                message: `The associate demonstrates brand loyalty throughout the customer service interaction, expressing confidence in the company and associating the brand with positive attributes to provide a positive customer experience.`
            },
            "neighborimpacting1": {
                category: "Neighbor Impacting",
                label: "N/A | Does not apply to review",
                message: `Does not apply`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Combative Chat/Argues with Neighbor",
                message: `Associate argues with our neighbor or becomes combative or threatening.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Blatant Chat/Work/Survey Avoidance",
                message: `Associate displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties.
                <br/><b>Example:</b>
                <ul>
                <li>Associate answers the chat 2 minutes and 30 seconds after it is routed to them.</li>
                <li>Closes the chat more than 1 minute after establishing the ending.</li>
                <li>Associate advises neighbor to call in when it's not necessary</li>
                <li>Associate uses NOT IN SCOPE salesforce disposition when they are able to assist the neighbor with the issue.</li>
                </ul>
                Please refer to the <a href="https://central.ring.com/leadership/cs-behavior-escalation-grid/">behavior escalation grid</a> for additional detail`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Sharing Internal Documents",
                message: `<b>Do not</b> send out any internal documents/screenshots of internal tools (i.e. Shelf, Salesforce, Stripe, etc..) to a neighbor.`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Did not use Replacement Verbiage",
                message: `Associate does not use the replacement verbiage as outlined in the <a href="https://ring.shelf.io/read/f3d75dfb-f67a-41b1-8c65-fc924f38bb33/?searchEventId=01J5BN3BK14KAPKP4FANFXFE1Q&source=shelf&trigger=title&view=snippet-view">How to Manage Ring.com Orders and Replacements</a> resource in Shelf`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Follow up expectations set were not completed",
                message: `Did the associate set expectations with the neighbor and not follow through (broken promises)?
                <br/><b>Example:</b>
                <ul>
                <li>Associate promised to make outbound contact and did not follow through</li>
                <li>Associate promised to send a follow-up or additional information via email and did not send one</li>
                <li>Associate promised a Supervisor callback and did not follow up with Supervisor to make sure it happens</li>
                </ul>`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Disclosing Personal Information",
                message: `To protect our neighbors and their information, Associates should never provide personal identifiable information (PII) to a neighbor that has not been verified, or is not the owner.
                <ul>
                <li>Case numbers are internal information and should *not* be given to the neighbor.</li>
                <li>PII should not be included in the notes. This is a security risk</li>
                </ul>
                Please refer to the <a href="https://ring.shelf.io/read/b4dc05ea-9bb9-4486-9432-2c72fba0cc3b/?searchEventId=01J5BNMH4PTDKY34937VQWH5GR&source=shelf&trigger=title&view=snippet-view">Account Verification and PII Information</a> resrouce in Shelf.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Account Verification and Device Authorization",
                message: `Associate did not correctly follow the <a href="https://ring.shelf.io/read/90b5226f-8989-48ea-bb63-3c03d88f42a8/">Account Verification Process</a> in Shelf`
            },
            "redflag8": {
                category: "Red Flags",
                label: "Invalid Replacement",
                message: `The associate processed a replacement for the neighbor when it was not appropriate to do so, or used the incorrect PLC replacement code.`
            },
            "redflag9": {
                category: "Red Flags",
                label: "Safety Concern",
                message: `The associate provided incorrect support steps or information to the neighbor that may result in a safety concern, or if an associate fails to escalate a Safety or Security concern through the proper channels.`
            },
            "redflag10": {
                category: "Red Flags",
                label: "Excessive Hold Times",
                message: `Hold times or dead air exceeding 3 minutes and 30 seconds with no check-In with the Neighbor.`
            }
        }
    }, "RSE Consultation v2": {
        type: "RSE Consultation v2",
        version: "V2",
        options: {
            "softskill1": {
                category: "Introduction",
                label: `Proper Introduction`,
                message: `Did the agent properly introduce themselves including their name and title as a Ring Security Expert?
                
                Expectation is to use "Ring Security Expert" and not a variation
                
                Neighbor should be acknowledged within 5 seconds of the call connecting`
            },
            "softskill2": {
                category: "Introduction",
                label: `Captured/Verified Neighbor Contact Info.`,
                message: `Did the agent capture missing contact details from the neighbor utilizing #RSE_Greeting?
                
                This includes verifying best callback number and email for follow up.`
            },
            "softskill3": {
                category: "Introduction",
                label: "Neighbor's Security Goals",
                message: `Was the agent able to establish clear understanding about why the neighbor was  seeking to add Ring devices to their home/business?`
            },
            "softskill4": {
                category: "Introduction",
                label: "Set Expectations for Security Consultation/Call",
                message: `Did the agent set call expectations of a consultation?
                *Shows Ownership and Willingness** ** to assist*
                *-OR-* 
                Explains scope of support (basic troubleshooting) before directing neighbor to appropriate team with a warm transfer.`
            },
            "callflow1": {
                category: "Discovery and Solutions",
                label: "Ring of Security - Neighborhood",
                message: `Did the agent inform Neighbor about the features and benefits of connecting with their neighbors? [Neighbors Tile in Ring App]`
            },
            "callflow2": {
                category: "Discovery and Solutions",
                label: "Ring of Security - Outside of Home",
                message: `Did the agent attempt to introduce potential security options for the outside of the neighbor's home?
                 
                -AND- 
                
                If neighbor showed interest, did agent ask relevant discovery questions?
                
                or,
                
                If agent attempted and neighbor declined, mark YES.`
            },
            "callflow3": {
                category: "Discovery and Solutions",
                label: "Ring of Security - Inside of Home",
                message: `Did  the agent attempt to introduce potential security options for the inside of  the neighbor's home?
                 
                -AND-  
                
                If neighbor showed interest, did the agent ask relevant discovery questions?
                
                or,
                
                If agent attempted and neighbor declined, mark YES.`
            },
            "callflow4": {
                category: "Discovery and Solutions",
                label: "Discuss WIFI Connectivity",
                message: `Did the agent attempt to identify environmental wifi impacts of the neighbor's home?
                 
                -AND- 
                
                If neighbor showed interest, did agent ask relevant discovery questions?
                
                -OR- 
                
                If agent attempted and neighbor declined, mark YES.`
            },
            "callflow5": {
                category: "Discovery and Solutions",
                label: "Discuss WWR Devices",
                message: `Did the agent introduce "Works With Ring" devices into the conversation?
                 
                -AND- 
                
                If neighbor showed interest, did the agent ask relevant discovery questions?
                
                -OR- 
                
                If agent attempted and neighbor declined, mark YES.`
            },
            "callflow6": {
                category: "Discovery and Solutions",
                label: "Discuss Ring Protect Plans (RPP)",
                message: `Did  the agent recommend and review Ring Protect Plan?`
            },
            "callflow7": {
                category: "Discovery and Solutions",
                label: "Discuss Installation Options",
                message: `Did the agent educate the neighbor on available installation options?
                
                - DIY (Self or agent assisted)
                - Ring's preferred installer, OnTech
                
                Did agent introduce the neighbor to relevant self-help options or other support channels?`
            },
            "callflow8": {
                category: "Discovery and Solutions",
                label: "Discovery Recap",
                message: `Did the agent align and gain agreement on the neighbor's security goals before recommending a solution?`
            },
            "callflow9": {
                category: "Discovery and Solutions",
                label: "Recommended the Right Solution",
                message: `Did the solution agent provided actually align with the neighbor's established security goals?
                
                -AND- 
                
                Did the agent educate the neighbor as to the features and benefits of the products/services as they relate to the neighbor's needs and solution offered?`
            },
            "callflow10": {
                category: "Discovery and Solutions",
                label: `RSE Exclusives + Promotions + Savings on Home Insurance`,
                message: `Did the agent identify If neighbor will qualify for additional savings or offers?
                 
                Agent must mention Evergreen promos on all calls such as, Everyday Heroes or Insurance Savings.`
            },
            "neighborimpacting1": {
                category: "Closing",
                label: "Ask For Sale",
                message: `Did the agent offer the sale?
                and, 
                If neighbor showed interest, did the agent send the permalink, use the proper Kustomer shortcut (#RSE_Permalink_Email) 
                and, 
                Did the agent remain on the line with the neighbor to complete the order, via ring.com?
                Tag: email_footer will need to be present on ticket
                If agent attempted and neighbor declined, mark YES.`
            },
            "neighborimpacting2": {
                category: "Closing",
                label: "Offer Permalink",
                message: `After asking for the sale, did the agent send a permalink using Neighbor Cart Builder and used the proper Kustomer shortcut (#RSE_Permalink_Email)?
                Tag: email_footer will need to be present on ticket
                and, 
                Did agent verify email was received by the neighbor?
                Mark Yes if order was completed.  
                If agent attempted and neighbor declined, mark YES.`
            },
            "neighborimpacting3": {
                category: "Closing",
                label: "Discuss Follow-Up Options",
                message: `Did the agent inform neighbor on ways to get back in touch with agent, or with Ring? (reply to e-mail or callback options)`
            },
            "neighborimpacting4": {
                category: "Closing",
                label: "Proper Closing",
                message: `Did the agent reiterate what the next steps are in the process? (delivery, transfer, permalink, etc)
                 
                Did agent confirm neighbor has no further questions or does not need further assistance?
                 
                Shows appreciation for the neighbor.
                 
                Brand services (Agent must say "Ring" in closing statement).`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Poor Interaction Control",
                message: `The agent did not maintain steady engagement throughout the life of the interaction.
                 
                The agent did not provide communication on what was being worked on.
                 
                The agent did not provide direction to the neighbor to move through the call.
                 
                The agent did not establish expectations during the call.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Clear Missed Opportunity to Resolve Neighbor Concern/Issue",
                message: `A clear opportunity to resolve the neighbor's issue or concern was present and  the agent did not follow through.`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Provided Inaccurate Information/Expectations",
                message: `The agent provided inaccurate information to the neighbor or did not take the  time to identify the correct information.
                 
                Quoted pricing for OnTech; Or mentioned other installation services from other companies (ie Amazon Home Service or Geek Squad).
                
                Did the agent set expectations with the neighbor and not follow through (broken promises)?
                 
                Examples:
                -  Inaccuracies with product information
                -  Overpromising shipping estimates
                -  Pricing`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Excessive Hold Time/Dead Air",
                message: `Hold  times exceeding 2 minutes and 30 seconds.
                 
                -OR- 
                 
                30 seconds of dead air with no check-in with the neighbor.`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Poor Notes",
                message: `Did not use the template from #RSE_Consult
                 
                Notes provided are not helpful to future calls related to the conversation.
                 
                -OR-  sends alternative email from approved format.
                 
                i.e Custom permalink emails
                 
                Ticket includes relevant subject line outlining initial reason for call.
                 
                i.e  "Consult" or "Video Doorbell Pro Troubleshooting"
                 
                Insights Panel filled out with neighbor details.`
            },"redflag6": {
                category: "Red Flags",
                label: "Work from Home Environment Concerns",
                message: `Excessive background noise can be heard on the call from the agents end.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Clear Inappropriate Tone and Demeanor",
                message: `The agent did not use appropriate tone and demeanor.
                 
                The agent was unprofessional and unwelcoming.
                 
                Being profane or making disparaging remarks about internal systems, Ring brand or other brands and partnerships.`
            },
            "redflag8": {
                category: "Red Flags",
                label: "Combative Call/Argues with Neighbor/Team Member",
                message: `The agent argues with the Neighbor or becomes combative/threatening.`
            },
            "redflag9": {
                category: "Red Flags",
                label: "Missing Notes",
                message: `There are no notes beyond #RSE_Greeting and follow up emails being sent (such as permalink) about this call.`
            },
            "redflag10": {
                category: "Red Flags",
                label: "Blatant Call/Work/Survey Avoidance",
                message: `The agent displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties.
                 
                Outbound Call Policy: If line disconnects, agent must callback neighbor; If no answer, leave voicemail and invite to call back in.
                 
                If there is silence at start of a call >35 Second (5 seconds + 30 seconds of dead air).`
            },
            "redflag11": {
                category: "Red Flags",
                label: "Sharing Internal Documents",
                message: `The agent shared internal documents/screenshots of internal tools (ie Admin  Panel, Kustomer, Stripe, etc.) to a neighbor.`
            },
            "redflag12": {
                category: "Red Flags",
                label: "Account Verification/Disclosing Personal Information/3-way call or Outbound Call Procedure",
                message: `Account Changes:
                The agent made changes to the account without the account holders (Name on the  account) authorization. 
 
                PII Error:
                The agent disclosed personal information (PII) about an account without validating Neighbor in Neighbor Dashboard.
                
                Neighbors are to confirm shipping addresses as opposed to the Team Member releasing  this type of sensitive information when sending replacements.
                
                Agents should never disclose account specific information about an account that is  not in the owner's name (e.g. name of the email address on the account).
                
                3-way call verbiage:
                There was an audible 3rd party on the line -OR-  original Neighbor gave the phone to  another person to continue the call and agent did not use 3-way call verbiage.`
            }
        }
    }, "Lennar v2": {
        type: "Lennar v2",
        version: "V2",
        options: {
            "softskill1": {
                category: "Introduction",
                label: "Proper Introduction",
                message: `Did the agent properly introduce themselves including their name and title as a Ring Security Expert?
                Expectation is to use "Ring Security Expert" and not a variation.
                Neighbor should be acknowledged within 5 seconds of the call connecting.`
            },
            "softskill2": {
                category: "Introduction",
                label: "Captured/Updated Neighbor Contact Information",
                message: `Did the agent capture missing contact details from the neighbor utilizing #RSE_Greeting?
                This includes verifying best callback number and email for follow up.`
            },
            "softskill3": {
                category: "Introduction",
                label: "Agent Understood Needs/Purpose for Call",
                message: `Was the agent able to establish clear understanding for the needs/purpose of the neighbor's call?`
            },
            "softskill4": {
                category: "Introduction",
                label: "Set Expectations for Lennar Support Call",
                message: `Did the agent set call expectations of a Lennar Support Call?
                Shows Ownership and Willingness to assist.
                -AND-
                Did the agent inform the neighbor the scope of support on Lennar Calls and provide an estimated duration for completion?`
            },
            "callflow1": {
                category: "Discovery and Support",
                label: `Validate Address/ Home Automation Package`,
                message: `Did the agent use the CHR Tool to identify home type and enter the correct info in the macro fields?
                -AND-
                Did agent review list of devices included with the Lennar Smart Home Package?`
            },
            "callflow2": {
                category: "Discovery and Support",
                label: `Followed Proper Steps of Service`,
                message: `Did the agent provide a resolution/support to the best of their abilities using the Lennar Call Flow?
                -AND-       
                Agent exhausted all troubleshooting options listed in the Lennar Call Flow before transfering to alternate support.`
            },
            "callflow3": {
                category: "Discovery and Support",
                label: "Proper Notes",
                message: `Agent used the template from #RSE_Lennar_Connected_Home.
                Agent used proper tags to indicate each device that was setup on call. (ie. #setup_eero, #setup_alarm, #setup_rvd, etc.)           
                Notes provided are helpful to future calls related to the conversation.     
                Agent sent appropriate email from approved format.
                i.e Connected_Home_Followup_email   
                Ticket includes relevant subject line outlining initial reason for call.
                i.e "Lennar Communities Connected by Ring"
                Insights Panel filled out with neighbor details.`
            },
            "callflow4": {
                category: "Discovery and Support",
                label: "PLC RVD Pro + Offer OnTech Installation",
                message: `If the RVD Pro needs to be replaced, did the agent process the PLC Replacement for the RVD Pro?
                -AND-    
                provide promo code for a free installation by OnTech?  
                -AND-
                Use correct macro #Lennar_Free_Pro_Install. 
                Mark Yes if neighbor declines professional install or N/A`
            },
            "callflow5": {
                category: "Discovery and Support",
                label: "Discuss RPP",
                message: `Did the agent recommend and review Ring Protect Plan as it relates to Lennar Homeowners?
                -AND-
                Outline relevant benefits based on recommended devices and services needed to fulfill neighbor's security goals confirming the account is eligible for RPP (1st Gen)?
                -AND-
                Agent reminded the neighbor of the end date of Ring Protect Plus (1st Gen) Plan.`
            },
            "callflow6": {
                category: "Discovery and Support",
                label: "Send Missing Ring Items",
                message: `Did the agent send Lennar Neighbor any missing Ring devices included in their Connected Home?
                -AND IF NEEDED-
                Did the agent provide Spare Parts promo code included in the Neighbor Cart Builder permalink and use the proper Kustomer shortcut (#RSE_Permalink_Email)?     
                Tag: email_footer will need to be present on ticket.
                -AND-
                Did agent verify email was received by the neighbor?
                Mark Yes if N/A`
            },
            "callflow7": {
                category: "Discovery and Support",
                label: "Offer Warm-Handoff for Additional Support",
                message: `Did the agent offer to do a warm hand-off /transfer for further support/troubleshooting/professional installation by 3P manufacturer, T2 alarm support or OnTech? If not able to transfer, did they provide Neighbor with contact info? 
                Mark Yes if N/A`
            },
            "neighborimpacting1": {
                category: "Closing",
                label: "Discuss Follow-Up Options",
                message: `Did the agent inform neighbor on ways to get back in touch with agent, or with Ring? (reply to follow-up e-mail or callback options)`
            },
            "neighborimpacting2": {
                category: "Closing",
                label: "Proper Closing",
                message: `Did the agent reiterate what the next steps are in the process (Lennar service request, Lennar support follow-up, callback, permalink, etc)?
                -AND-  
                Did agent confirm neighbor has no further questions or does not need further assistance?
                Agent shows appreciation for the neighbor.
                Brand services (Agent must say "Ring" in closing statement)`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Poor Interaction Control",
                message: `The agent did not maintain steady engagement throughout the life of the interaction.
                The agent did not provide communication on what was being worked on.       
                The agent did not provide direction to the neighbor to move through the call. 
                The agent did not establish expectations during the call.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Clear Missed Opportunity to Resolve Nx Issue",
                message: `A clear opportunity to resolve the neighbor's issue or concern was present and the Expert did not follow through.
                Agent does not assure the Neighbor that they will help and take responsibility for a resolution.`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Excessive Hold Times or Dead Air",
                message: `Hold times exceeding 2 minutes and 30 seconds
                -OR-
                Silence at start of call on agents side is 16-35 seconds`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Work from Home Environment Concerns",
                message: `Excessive background noise can be heard on the call from the agents end.`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Clear Inappropriate tone and demeanor",
                message: `The agent did not use appropriate tone and demeanor. 
                The agent was unprofessional and unwelcoming.
                Being profane or making disparaging remarks about internal systems, Ring brand, Lennar or other brands and partnerships.`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Combative Call/Argues with Neighbor",
                message: `The agent argues with the Neighbor or becomes combative/threatening.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Missing Notes",
                message: `There are no notes beyond #RSE_Greeting and follow up emails being sent (such as Lennar Connected Home Follow-up email) about this call.`
            },
            "redflag8": {
                category: "Red Flags",
                label: "Blatant Call/Work/Survey Avoidance",
                message: `Team member displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties. Please refer to the behavior escalation grid for additional detail: https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag9": {
                category: "Red Flags",
                label: "Share Internal Documents",
                message: `The agent shared internal documents/screenshots of internal tools (ie Admin Panel, Kustomer, Stripe, etc.) to a neighbor.`
            },
            "redflag10": {
                category: "Red Flags",
                label: "Verification/Disclosing Personal Information/3-way call",
                message: `Account Changes:
                The agent made changes to the account without the account holders (Name on the account) authorization. 
                PII Error:
                The agent disclosed personal information (PII) about an account without verifying Neighbor in Neighbor Dashboard.
                Neighbors are to confirm shipping addresses as opposed to the Team Member releasing this type of sensitive information. 
                Agents should never disclose account specific information about an account that is not in the owner's name (e.g. name of the email address on the account).
                3-way call verbiage:
                There was an audible 3rd party on the line or, original Neighbor gave the phone to another person to continue the call and agent did not use 3-way call verbiage.`
            }
        }
    }, "NA | S2T2": {
        type: "NA | S2T2",
        version: "V1",
        options: {
            "callflow1": {
                category: "Professional Communication & Call Flow",
                label: "Proper Introduction",
                message: `Team member used professional introduction: states their name, Ring, department, and confirms it is a good time to speak.`
            },
            "callflow2": {
                category: "Professional Communication & Call Flow",
                label: "Ring Voice",
                message: `The team member expressed understanding of the neighbor's concern by using a positive, empathetic tone throughout the course of the ticket. The team member was clear and concise, providing education and proper expectations along the way.`
            },
            "callflow3": {
                category: "Professional Communication & Call Flow",
                label: "Avoid Using ASF",
                message: `The team member avoided using ASF terms or negative language during the call.`
            },
            "callflow4": {
                category: "Professional Communication & Call Flow",
                label: "Positive Empathy - Email",
                message: `Agent developed and used an effective empathy statement in their first contact and or first follow up email, that follows the flow of the Empathy Equation (Value Builder, Empathize, Reassure)`
            },
            "callflow5": {
                category: "Professional Communication & Call Flow",
                label: "Clear Communication",
                message: `Three or fewer errors in grammar or punctuation combined.  Unless, a single error in grammar or punctuation changes the meaning of a sentence (this would result in loss of points). Any spelling errors would result in a loss of points.`
            },
            "callflow6": {
                category: "Professional Communication & Call Flow",
                label: "Restating the Issue and set the agenda for the call.",
                message: `Team member set the expectations for the call so the neighbor knows what to expect. Team member restates the neighbor's issue in their own words, demonstrating an understanding of the issue/concern.`
            },
            "callflow7": {
                category: "Professional Communication & Call Flow",
                label: "Effective Troubleshooting and probing for clarification.",
                message: `Did the team member troubleshoot according to the game plan, asking appropriate probing questions and adjusting as needed based on customer input or troubleshooting results?`
            },
            "callflow8": {
                category: "Professional Communication & Call Flow",
                label: "Did the team member ask for further questions or concerns",
                message: `While on the call, did the team member ask the neighbor if they had any additional questions or needs?`
            },
            "callflow9": {
                category: "Professional Communication & Call Flow",
                label: "Accurate education/information",
                message: `Did the TM give accurate information throughout the lifecycle of a ticket`
            },
            "callflow10": {
                category: "Professional Communication & Call Flow",
                label: "Ticket handling",
                message: `Team member sent a follow-up email and left updated, detailed notes after each phone call. Team member updated ticket with any significant update from leadership/neighbor/other teams in org.`
            },
            "softskill1": {
                category: "Neighbor Obsession (Email - Call)",
                label: "Game Plan/Same Day Contact",
                message: `Did the team member create a complete game plan that was posted prior to contacting the neighbor? Was neighbor outreach established the same day the escalation was received unless otherwise direct by leadership? `
            },
            "softskill2": {
                category: "Neighbor Obsession (Email - Call)",
                label: "Address the Issue | Ownership",
                message: `Did the team member display a willingness to assist the neighbor and own the ticket to resolution?`
            },
            "softskill3": {
                category: "Neighbor Obsession (Email - Call)",
                label: "Insight Panel",
                message: `Did the team member fully complete the Kustomer Insight/CS/Amazon Panel with the appropriate information?`
            },
            "softskill4": {
                category: "Neighbor Obsession (Email - Call)",
                label: " Offer reasonable resolution",
                message: `Did the team member resolve the neighbor's concerns or provide the neighbor with the most  appropriate next steps in a case where an outright resolution is not presently available?`
            },
            "softskill5": {
                category: "Neighbor Obsession (Email - Call)",
                label: "SLA",
                message: `Did the team member maintain contact with the neighbor within the appropriate SLA? Unless otherwise notated.`
            },
            "neighborimpacting1": {
                category: "Team Specific Workflow",
                label: "Call Summary/Next Steps and Resources",
                message: `Before disconnecting the call, the team member provided the neighbor with a summary of the phone call; including information about next steps (if applicable), and provided self help links/sources if applicable.`
            },
            "neighborimpacting2": {
                category: "Team Specific Workflow",
                label: "Did the team member use the most effective form of communication?",
                message: `If within comfort hours, did the TM attempt a phone call as first contact? And unless otherwise directed by the neighbor or stakeholders, did the TM default to phone contact for troubleshooting/probing questions, adhering to the”3-touch rule” to resolve the neighbors concerns?`
            },
            "neighborimpacting3": {
                category: "Team Specific Workflow",
                label: "Did the team member attempt two callbacks for the scheduled appointment? ",
                message: `Did the team member attempt to contact the neighbor twice for scheduled callback if the first was unanswered?  And did the team member leave a voicemail after each phone call attempt?`
            },
            "neighborimpacting4": {
                category: "Team Specific Workflow",
                label: "Proper ticket process?",
                message: `Team member added proper opening & closing tags to the ticket. Team member has fully completed the closing notes macro and posted it to the ticket before closing. Safety only: Is the TT number noted - only if Valid? `
            },
            "redflag1": {
                category: "Red Flags",
                label: "Security Fail",
                message: `Account verification was not completed by the team member prior to discussing account-specific information or taking action on an account. Information was offered by the team member that was not confirmed by the neighbor prior. Information was given by the team member pertaining to another user’s account.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Unauthorized Account Changes",
                message: `Team member took irreversible action on an account that was not requested by the neighbor. (ex: account deletion, PLC/replacement, RPP cancellation/loss of videos at the hand of team member/removing tags)`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Sharing internal documents",
                message: `Team member sent out internal documents/screenshots of internal tools and/or resources. (admin/dashboard, Ring Central, Kustomer, etc.)`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Failure to set critical expectations",
                message: `Team member failed to notify neighbor of critical adjustments to their products and/or services.(warranty information, video retention information, Financial transactions, account deletions, etc.)`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Follow up expectations not met.",
                message: `Team member did not follow through on their promise to the neighbor such as adhering to scheduled callbacks, processing parts/replacements, sending emails, etc.`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Incorrect troubleshooting or information given",
                message: `Incorrect troubleshooting or information given that may cause a safety concern (wiring).`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Suggesting a product is unreliable.",
                message: `Team members used verbiage that alluded to our products being unreliable, not fit for purpose or paints our products in a negative light. `
            },
            "redflag8": {
                category: "Red Flags",
                label: "Bad-mouthing Ring or Leadership, other agents or Ring Departments.",
                message: `Team member addressed the neighbor's complaints/feedback towards support and/or leadership in a manner that paints them in a negative light or suggests incompetence.`
            },
            "redflag9": {
                category: "Red Flags",
                label: "Using language or jokes that may not be appreciated by the neighbor.",
                message: `Using language or jokes that may offend the neighbor.`
            },
            "redflag10": {
                category: "Red Flags",
                label: "Work From Home Environment Concerns",
                message: `Team member exhibited behavior or activity that violates the Work From Home Policy`
            },
            "redflag11": {
                category: "Red Flags",
                label: "Excessive Hold Times",
                message: `Hold times exceeding two minutes without checking in on the neighbor.`
            }
        }
    }, "Access Team V2": {
        type: "Access Team V2",
        version: "V2",
        options: {
            "callflow1": {
                category: "Communication & Call Flow",
                label: "Proper Introduction and Restate Issue",
                message: `Did the team member properly introduce themselves and acknowledge the agent chatting in with the request? Did the team member restate the issue or task?`
            },
            "callflow2": {
                category: "Communication & Call Flow",
                label: "Tone & Pleasantries",
                message: `Did the team member display a professional/courteous tone in written communications?`
            },
            "callflow3": {
                category: "Communication & Call Flow",
                label: "Process to Follow",
                message: `Did the team member ensure the proper process was followed according to the documentation in Ring Central? Includes asking probing/clarifying questions for the process?`
            },
            "callflow4": {
                category: "Communication & Call Flow",
                label: "Communication & Comprehension",
                message: `Is the conversation understandable, digestible, and appropriate?`
            },
            "callflow5": {
                category: "Communication & Call Flow",
                label: "Appropriate check-in",
                message: `Did the team member check in with the L1 during periods of hold?`
            },
            "callflow6": {
                category: "Communication & Call Flow",
                label: "Team member/customer education",
                message: `Did the team member provide the proper education per process?`
            },
            "callflow7": {
                category: "Communication & Call Flow",
                label: "Confirm and communicate the issue resolved",
                message: `Did the team member communicate that the issue has been resolved and did they confirm with the L1 that the fix worked (OTP exception). `
            },
            "callflow8": {
                category: "Communication & Call Flow",
                label: "Closing note",
                message: `Did the team member add the required internal notes per specific process and include a closing note/recap inside the Kustomer account?`
            },
            "softskill1": {
                category: "Neighbor Obsession (Email - Call)",
                label: "Verify authentication",
                message: `Did the team member verify the neighbor was properly authenticated? (copied and pasted into ticket)`
            },
            "softskill2": {
                category: "Neighbor Obsession (Email - Call)",
                label: "Tags",
                message: `Did the team member accurately tag the L1/NX ticket per process?`
            },
            "softskill3": {
                category: "Neighbor Obsession (Email - Call)",
                label: "Insight Panel",
                message: `Did the team member accurately disposition Customer's Insight Panel?`
            },
            "softskill4": {
                category: "Neighbor Obsession (Email - Call)",
                label: "Follow up emails",
                message: `Did the team member send the appropriate follow up emails per specific process?`
            },
            "neighborimpacting1": {
                category: "Neighbor Engagement",
                label: "Ownership/Diligence.",
                message: `Did the team member display ownership of the issue and ensure they did their due diligence within the conversation?`
            },
            "neighborimpacting2": {
                category: "Neighbor Engagement",
                label: "Explain the why",
                message: `Did the team member explain why certain information or documentation was needed so they could relay this to the neighbor?`
            },
            "neighborimpacting3": {
                category: "Neighbor Engagement",
                label: "Proper disconnect",
                message: `Did the team member properly disconnect the chat after it was finished?`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Combative Call/Argues with Neighbor",
                message: `Argues with our Neighbor and/or becomes combative/threatening.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Sharing internal documents",
                message: `"DO NOT send out any internal documents/screenshots of internal tools (ie. Admin Panel, Kustomer, Stripe, etc.) to a Neighbor
                (https://support.ring.com/hc/en-us)"`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Follow Up expectations steps were not completed",
                message: `Did the Team Member set expectations with the Neighbor and not follow through (broken promises)? 
                i.e. Promising to send a follow up email and not sending one.
                Promising the Neighbor that your Supervisor will call them back and not following up with your Supervisor to make sure that happens.`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Account Verification and Device Authorization",
                message: `"Learn More Click Here:
                https://central.ring.com/support_article/account-verification-and-device-authorization/"`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Invalid Replacement",
                message: `https://central.ring.com/support_article/pre-plc-replacement-and-return-process/`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Invalid PLC Replacement Reason Code",
                message: `Did the Team Member set expectations with the Neighbor and not follow through (broken promises)? 
                i.e. Promising to send a follow up email and not sending one.
                Promising the Neighbor that your Supervisor will call them back and not following up with your Supervisor to make sure that happens.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Confirm detail, review notes, disable 2fa, re-enable",
                message: `Did the team member display ownership of the issue and ensure they did their due diligence within the conversation?`
            },
            "redflag8": {
                category: "Red Flags",
                label: "Blatant Chat/Work/Survey Avoidance",
                message: `Team member displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties.
                I.E. (Not all inclusive) Greeting the team member 4 minutes after it’s been routed to them. Not closing the chat in a timely manner. 
                I.E. 4 minutes after a resolution was confirmed. 6 minutes after a resolution was communicated but not confirmed. Prematurely disconnecting chats. 
                Agents should be waiting, allow the team member time to get the things they need from the Neighbor. 
                I.E. They should not be saying “contact us back when you have it” and immediately disconnecting. 
                Excessive holds/dead air: More than 5 minutes on hold or dead air. 
                Skipping part or all of a process altogether to get off the chat faster.`
            }
        }
    }, "ALT": {
        type: "ALT",
        version: "V1",
        options: {
            "callflow1": {
                category: "Professional Communication & Call Flow",
                label: "Proper Introduction",
                message: `"Professional greetings using the proper verbiage.
                Using the corresponding Kustomer Shortcut (when/if applicable)#ALT_Follow_up"`
            },
            "callflow2": {
                category: "Professional Communication & Call Flow",
                label: "Restates our Neighbors experince?",
                message: `Restating our Neighbor's reason for contacting Ring confirms to our Neighbor that you understand and are actively engaged in the conversation/email.`
            },
            "callflow3": {
                category: "Professional Communication & Call Flow",
                label: "Grammar/Spelling",
                message: `Proper email etiquette, correct punctuation, spelling, grammar. `
            },
            "callflow4": {
                category: "Professional Communication & Call Flow",
                label: "Showed willingness to assist?",
                message: `Ring is looking for statements showing you are enthusiastic about assisting our Neighbor.
                When Neighbors reach out for assistance we must do our due diligence in providing them white glove service.`
            },
            "callflow5": {
                category: "Professional Communication & Call Flow",
                label: "Shows empathy?",
                message: `Team Member conveyed to our Neighbor that they understand and reciprocate/share their feelings regarding the issue/concern`
            },
            "callflow6": {
                category: "Professional Communication & Call Flow",
                label: "Were probing questions asked?",
                message: `Ring is looking for probing questions early in the email, to ensure we are not going back and forth with the nieghbor via email. If no probing questions are needed, automatic yes.`
            },
            "callflow7": {
                category: "Professional Communication & Call Flow",
                label: "Verify Resolution and Set Future Expectations",
                message: `Ring is looking for Team Members to set expectations of next steps /expecations of wait times with neighbors.`
            },
            "callflow8": {
                category: "Professional Communication & Call Flow",
                label: "Closed Positively",
                message: `Closed Positively:
            Ends conversation/Email on a positive note`
            },
            "softskill1": {
                category: "N/A - Does not apply",
                label: "N/A at this time",
                message: `N/A at this time`
            },
            "neighborimpacting1": {
                category: "Neighbor Impacting Steps",
                label: "Followed Correct Process Per Policy.",
                message: `Were the correct steps followed for the process? ALT internal notes macro utilized. Explaining exactly what occurred.`
            },
            "neighborimpacting2": {
                category: "Neighbor Impacting Steps",
                label: "Subject Line and Insight Panel Completed",
                message: `Are all fields in the Insight Panel completed?
                Neighbor name, email, and phone number
                Product
                MAC ID
                Platform
                Resolution/ Was closing notes survey filled out?`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Closing Notes Macro not Utilized",
                message: `Closing notes not applied to the ticket prior to marking the ticket done. Failure to send out the ALT survey.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Combative Email-Argues with Neighbor",
                message: `Argues with our Neighbor and/or becomes combative/threatening.`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Sharing internal documents",
                message: `"DO NOT send out any internal documents/screenshots of internal tools (ie. Admin Panel, Kustomer, Stripe, etc.) to a Neighbor
                (https://support.ring.com/hc/en-us)"`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Did not use Replacement Verbiage",
                message: `https://central.ring.com/support_article/pre-plc-replacement-and-return-process/`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Follow Up expectations steps were not completed",
                message: `Did the Team Member set expectations with the Neighbor and not follow through (broken promises)? 
                i.e. Promising to send a follow up email and not sending one.
                Promising the Neighbor that your Supervisor will call them back and not following up with your Supervisor to make sure that happens.`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Account Verification and Device Authorization",
                message: `"Learn More Click Here:
                https://central.ring.com/support_article/account-verification-and-device-authorization/"`
            }
        }
    }, "ALT RC3 Chat": {
        type: "ALT RC3 Chat",
        version: "V1",
        options: {
            "callflow1": {
                category: "Communication & Email Flow",
                label: "Proper agent acknowledgment and restate issue",
                message: `Did the team member properly acknowledge the L1/agent chatting in with the request (use agent name)? 
                Did the team member restate the issue or task? TM should accept the chat within 20 seconds and acknowledge the chat within 60 seconds.`
            },
            "callflow2": {
                category: "Communication & Email Flow",
                label: "Tone & Pleasantries",
                message: `Did the team member display a professional/courteous tone in written communications?`
            },
            "callflow3": {
                category: "Communication & Email Flow",
                label: "Process to Follow",
                message: `Did the team member ensure the proper process was followed according to the documentation in Ring Central? Includes asking probing/clarifying questions for the process?`
            },
            "callflow4": {
                category: "Communication & Email Flow",
                label: "Communication and Comprehension?",
                message: `Is the conversation understandable, digestible, and appropriate? The conversation is easy to follow and directions are clearly understood by all parties.`
            },
            "callflow5": {
                category: "Communication & Email Flow",
                label: "Appropriate check-in",
                message: `Did the team member check in with the L1 during periods of hold? Max hold time can be up to 3 min.`
            },
            "callflow6": {
                category: "Communication & Email Flow",
                label: "Team member/customer education",
                message: `Did the team member provide the proper education per process (If there is education that is needed, i.e., did the L1 agent come to ALT when it was an issue they could have handled themselves and did the ALT agent educate them on where to find the information?)`
            },
            "callflow7": {
                category: "Communication & Email Flow",
                label: "Confirm and communicate the issue resolved",
                message: `TM has advised inbound TM that their issue has been resolved or has advised them of next steps needed for resolution 
                The TM will be evaluated on whether they outlined an effective and attainable resolution that would be most impactful to the Neighbor experience 
                The TM communicated that the issue has been resolved and confirmed with the L1 that the fix worked and that the neighbor received everything needed to resolve the issue.`
            },
            "callflow8": {
                category: "Communication & Email Flow",
                label: "Internal/Closing note",
                message: `Did the team member add the required internal notes per specific process and include a closing note/recap inside the Kustomer account?`
            },
            "softskill1": {
                category: "Process Adherence",
                label: "Verify authentication",
                message: `Did the team member verify the neighbor was properly authenticated? (copied and pasted into ticket)`
            },
            "softskill2": {
                category: "Process Adherence",
                label: "Insight Panel",
                message: `Did the team member accurately disposition Customer's Insight Panel?`
            },
            "neighborimpacting1": {
                category: "Neighbor Engagement",
                label: "Ownership/Diligence",
                message: `Did the team member display ownership of the issue and ensure they did their due diligence within the conversation?`
            },
            "neighborimpacting2": {
                category: "Neighbor Engagement",
                label: "Explain the why",
                message: `Did the team member explain why certain information or documentation was needed so they could relay this to the neighbor (specifically when asking the NX for something)?`
            },
            "neighborimpacting3": {
                category: "Neighbor Engagement",
                label: "Proper disconnect",
                message: `Did the team member properly disconnect the chat after it was finished?`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Combative Chat/Argues with L1 Agent",
                message: `Team Member argues with our Neighbor or becomes combative/threatening.`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Sharing Internal Documents/Processes",
                message: `DO NOT send out any internal documents/screenshots of internal tools (i.e.. Admin Panel, Kustomer, Stripe, etc.) to a Neighbor`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Account Verification and Device Authorization",
                message: `https://central.ring.com/support_article/account-verification-and-device-authorization/`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Invalid Replacement Sent",
                message: `Team Member did not perform all necessary troubleshooting steps or follow process correctly before processing a replacement for the Neighbor.`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Invalid PLC Replacement Reason Code",
                message: `Team Member did not select the correct PLC Replacement Reason Code that correlates with the reason for replacement.`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Failure to own overall experience",
                message: `Team member significantly lacked ownership or due diligence, which may result in additional effort on the neighbors part to further resolve. Additional egregious behaviors include, but are not limited to, unnecessary financial impacts or information/actions provided or taken that would require the neighbor to callback.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Blatant Chat/Work/Survey Avoidance",
                message: `Team member displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties. 
                I.E. (Not all inclusive) Greeting the team member 4 minutes after it’s been routed to them. Not closing the chat in a timely manner. I.E. 4 minutes after a resolution was confirmed. 6 minutes after a resolution was communicated but not confirmed. Prematurely disconnecting chats. Agents should be waiting, allow the team member time to get the things they need from the Neighbor. I.E. They should not be saying “contact us back when you have it” and immediately disconnecting.
                Excessive holds/dead air: More than 5 minutes on hold or dead air.
                Skipping part or all of a process altogether to get off the chat faster.`
            }
        }
    }, "Amazon Review - PR v1": {
        type: "Amazon Review - PR v1",
        version: "V1",
        options: {
            "callflow1": {
                category: "Professional Communication",
                label: "Grammar",
                message: `Team member used proper grammar. Grammar is defined as simply the collection of principles defining how to put together a sentence.`
            },
            "callflow2": {
                category: "Professional Communication",
                label: "Spelling",
                message: `Team member used proper spelling. Spelling is defined as the proper way to write a word, using the correct order of letters.`
            },
            "callflow3": {
                category: "Professional Communication",
                label: "Punctuation",
                message: `Team member used proper punctuation. Every sentence should include at least a capital letter at the start, and a full stop, exclamation mark, or question mark at the end. This basic system indicates that the sentence is complete.`
            },
            "callflow4": {
                category: "Professional Communication",
                label: "Sentence Structure",
                message: `Team member used proper sentence structure. There are four types of sentences: simple, compound, complex, and compound-complex. Each sentence is defined by the use of independent and dependent clauses, conjunctions, and subordinators.`
            },
            "callflow5": {
                category: "Professional Communication",
                label: "Formatting",
                message: `Team member used proper formatting when responding to a review or question. Formatting refers to the appearance of the text and the simplicity of how the reader observes the conveyed message.`
            },
            "callflow6": {
                category: "Professional Communication",
                label: "Personalization",
                message: `Team member personalized their response with unique conversation by using the neighbor's name, was thankful for the provided details, showing genuine excitement to provide support. Personzaliztion is defined as: the action of designing or producing something to meet someone's individual requirements.`
            },
            "callflow7": {
                category: "Professional Communication",
                label: "User Name/Ring Name",
                message: `Team member referred to the neighbor by their screen name, and signed the response with their first name from/with Ring. (When an inappropriate screen name is used, it is acceptable to refer to the neighbor as "Ring Neighbor")`
            },
            "softskill1": {
                category: "Conversational Response",
                label: "Empathy",
                message: `Team member used a powerful 3-part empathy statement, early in the review response, in order to convey a genuine interest in assisting with resolving the neighbor's concern.  1. Opening statement 2. Value point of why their concern is important.  3. Reassurance   (Questions & Answers do not require empathy)`
            },
            "softskill2": {
                category: "Conversational Response",
                label: "Ring Voice",
                message: `Team member had a "Ring Voice". They stayed positive and informative while conducting the question/review response. Team member did not use words like apologize, sorry, or frustrated. `
            },
            "softskill3": {
                category: "Conversational Response",
                label: "Neighbor Education",
                message: `Team member provided helpful troubleshooting steps and/or provided tips, tricks relevant to the issue(s) mentioned in the review or question. If there are 2 or less techincal issues mentioned in the review, we will address both. If there are 3 or more, team member is required to only provide tips and tricks for 2.   TM also provided a support article in the question/review if there is a relevant article pertaining to the question or concern.`
            },
            "softskill4": {
                category: "Conversational Response",
                label: "Restate/Address Issue",
                message: `Team member restated the neighbor's concern in their own words. Team member addressed the issue(s) mentioned in the question/review. If there are 2 or less issues mentioned in the review, we will address both. If there are 3 or more, team member is required to address two.   For a question response, a direct “yes” or “no” response is necessary if the question requires a "yes" or "no" statement/answer.`
            },
            "neighborimpacting1": {
                category: "Neighbor Engagement",
                label: "Ownership",
                message: `Team member demonstrated ownership on getting in contact with the organization by providing the Ring Community forum link.`
            },
            "neighborimpacting2": {
                category: "Neighbor Engagement",
                label: "Expectations",
                message: `Team member reset intended expectations of the device OR provided proper technical specifications for the device mentioned within the question/review.`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Publicly announcing a trending or known issue",
                message: `Publicly announcing a trending or known issue.

                https://central.ring.com/support_article/critical-issues-communication-process/?hilite=trending+issues`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Using inapproriate or profane language",
                message: `Using inapproriate or profane language.

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Use of internal abbreviations",
                message: `Use of internal abbreviations (examples include: RVD/FLC/SUC/SLC).

                A full list of internal abreivations can be found here: https://central.ring.com/support_article/product-comparison/?hilite=internal+abreviations. `
            },
            "redflag4": {
                category: "Red Flags",
                label: "Sharing Internal Documents / Information",
                message: `Team member sent out internal documents/screenshots of internal tools and/or resources (admin/dashboard, Ring Central, Kustomer, etc.).

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Suggesting a product is unreliable",
                message: `Team members used verbiage that alluded to our product(s) being unreliable, not fit for purpose, or portrayed our products in a negative light.

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            }
        }
    }, "Amazon Review - RS v2": {
        type: "Amazon Review - RS v2",
        version: "V1",
        options: {
            "callflow1": {
                category: "Call Flow",
                label: "Name & Review",
                message: `Team member stated their first name, that they are calling from Ring and they made a mention of the review they are calling about.`
            },
            "callflow2": {
                category: "Call Flow",
                label: "Courteous of neighbors time",
                message: `Team member asked and/or confirmed if it was a good time to speak.`
            },
            "callflow3": {
                category: "Call Flow",
                label: "Call - Restate",
                message: `Team member restated all of the issues/concern mentioned in the Amazon Review, in their own words.`
            },
            "callflow4": {
                category: "Call Flow",
                label: "Call - Empathy",
                message: `Team member provided a 3 part power empathy statement early on in the phone call.  1. Opening statement 2. Value point of why their concern is important.  3. Reassurance statement to get towards a solution.`
            },
            "callflow5": {
                category: "Call Flow",
                label: "Ring Voice",
                message: `Team member avoided negative word usage like apologize, sorry, or frustrated on the phone call.`
            },
            "callflow6": {
                category: "Call Flow",
                label: "Troubleshooting, Billing, and Shipping",
                message: `Team member exhausted all troubleshooting steps before offering a concession; such as a replacement or refund.  Team member used the proper billing and shipping processes when necessary. This includes setting the proper expectations prior to any submissions and including relevant information within a follow up email.`
            },
            "callflow7": {
                category: "Call Flow",
                label: "Clear & Concise Communication",
                message: `Team member used clear and concise communication when speaking with the neighbor.`
            },
            "callflow8": {
                category: "Call Flow",
                label: "Cold Calls",
                message: `Given there was a phone number on file, team member placed a cold call within 1-2 hours of sending their intro email and again after 24-hours if the neighbor never responded, before putting the ticket into automation.`
            },
            "callflow9": {
                category: "Call Flow",
                label: "Recap Email",
                message: `Team member provided the neighbor with a clear recap email, within two hours of disconnecting the phone call, summarizing what happened on the call.`
            },
            "softskill1": {
                category: "First Contact Email & Game Planning",
                label: "Introduction Macro",
                message: `Team member used the proper introduction macro of #amazon_review_intro & sent an introduction email the same day the ticket was assigned.`
            },
            "softskill2": {
                category: "First Contact Email & Game Planning",
                label: "First Contact Email - Restate",
                message: `Team member restated the issue that was mentioned in the negative Amazon Review in their own words on the initial email sent out to the neighbor. If the neighbor mentions 1-2 issues, we should mention both issues in the initial email. If the nx provides 3 or more issues, the agent should specify two and acknowledge that any additional concerns mentioned in their review can be resolved, as well.`
            },
            "softskill3": {
                category: "First Contact Email & Game Planning",
                label: "Edits",
                message: `Team member used proper spelling of words, puncutation marks, and grammar when making edits to the introduction email macro.   Team member was also successful in adding in the proper hyperlink to the negative review.`
            },
            "softskill4": {
                category: "First Contact Email & Game Planning",
                label: "Game Plan - Restate issue",
                message: `Team member restated the issue(s) that was mentioned in the negative Amazon Review, in their own words, within the game plan they have created. All issues will be listed out, regardless of number of issues.`
            },
            "softskill5": {
                category: "First Contact Email & Game Planning",
                label: "Game Plan - Outline steps to a resolution",
                message: `Team member effectively outlined what steps will be taken during the call to reach a solution. The steps should outline how a resolution will be put in place for all issues that were outlined in the review. This is including but not limited to: troubleshooting steps, previous notes from a reviewed phone call (with next steps or steps they are eliminating), information relevant to the issue that was found on DB/Admin.`
            },
            "softskill6": {
                category: "First Contact Email & Game Planning",
                label: "Game Plan - Empathy",
                message: `Team member outlined a power empathy statement related to the neighbor's issue in the Amazon review. The power empathy statement incudes a 3-part empathy statement of the following:  1. Opening statement 2. Value point of why their concern is important.  3. Reassurance statement to get towards a solution.`
            },
            "neighborimpacting1": {
                category: "Communication & Ticket Handling",
                label: "Concise & Relevant Information and Meeting SLA",
                message: `Team member provided concise and relevant information during email communications. Maintained a proper SLA by meeting comitments set with neighbor and no long snooze gaps with no notation as to why. There are two different SLA’s to meet:
                •72 Hours SLA –Team Member has already sent an email but the Neighbor has yet to respond. This 72 business-hours SLA is specifically for when we are waiting for the Neighbor to respond and attempting to reengage.
                •24 Hours SLA – The Neighbor has responded to the Team Member and is expecting or waiting for a response. Ideally, Amazon Review Team Members should reply on the same day, however, any response within 24 business hours is acceptable.`
            },
            "neighborimpacting2": {
                category: "Communication & Ticket Handling",
                label: "Complete Sentences / Proper Grammar / Formatting / Spelling / Punctuation",
                message: `Team member used complete sentences with proper grammar and formatting during email communications. Team member used proper spelling and puntuations during email communications. You can have up to 3 mistakes within your entire ticket handling. Unless there is a miss that changes the context of the sentence.`
            },
            "neighborimpacting3": {
                category: "Communication & Ticket Handling",
                label: "Closing Notes",
                message: `Team member used the closing notes macro (#amazon_closing_notes) when marking the ticket as done in Kustomer. Team member had all 3 sections acknowledged and if it did not pertain, they place a N/A or something similiar to indicate that section is not relevant.`
            },
            "neighborimpacting4": {
                category: "Communication & Ticket Handling",
                label: "Insight Panels",
                message: `Team member was successful in filling out the proper dispositions for the CS Insight panel AND Amazon Review insight panel. Amazon ID is NOT required to be filled out in the amazon review insight panel.`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Combative Call/Argues with Neighbor",
                message: `Argues with our Neighbor and/or becomes combative/threatening.

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag2": {
                category: "Red Flags",
                label: "Bad-mouthing Ring, Leadership, other agents or Ring Departments.",
                message: `Team member addressed the neighbor's complaints/feedback towards support and/or leadership in a manner that paints them in a negative light or suggests incompetence.

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag3": {
                category: "Red Flags",
                label: "Sharing Internal Documents",
                message: `Team member sent out internal documents/screenshots of internal tools and/or resources (admin/dashboard, Ring Central, Kustomer, etc.).

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag4": {
                category: "Red Flags",
                label: "Did not use outbound call verbiage, when required.",
                message: `Team member did not use outbound call verbiage as outlined in the Outbound, Callback, and 3-Way Call Process policy.

                https://central.ring.com/support_article/outbound-and-callback-process/?hilite=outbound+call+verbiage#tab-con-6`
            },
            "redflag5": {
                category: "Red Flags",
                label: "Follow up expectations were not completed",
                message: `The team member did not follow through on their promise to the neighbor, such as: adhering to scheduled callback request(s); processing parts or replacements; sending emails; or the agent missed a scheduled appointment.

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            },
            "redflag6": {
                category: "Red Flags",
                label: "Account Verification and Device Authorization",
                message: `Account verification was not completed by the team member prior to discussing account-specific information or taking action on an account, or information pertaining to another user's account was given by the team member. PII was offered by the team member that was not confirmed by the neighbor prior. Any information (device type, issue, RPP status, etc.) contained within the review shall be considered public knowledge and not PII during our team's introduction email and introduction call flow.

                PII is defined here: https://central.ring.com/support_article/account-verification-and-device-authorization/?hilite=account+verification#tab-con-22
                A copy of the Account Verification Policy can be found here: https://central.ring.com/support_article/account-verification-and-device-authorization/?hilite=account+verification.`
            },
            "redflag7": {
                category: "Red Flags",
                label: "Work From Home Environment Concerns",
                message: `Team member exhibited behavior or activity that violates the companies Work From Home Policy.

                https://central.ring.com/support_article/ring-customer-support-work-from-home-policy/?hilite=WORK+HOME+POLICY`
            },
            "redflag8": {
                category: "Red Flags",
                label: "Proper Email Signature Used in Communications",
                message: `An improper email signature was used in communications with the neighbor.

                https://central.ring.com/support_article/kustomer-email-signature-policy/?hilite=email+signature`
            },
            "redflag9": {
                category: "Red Flags",
                label: "Blatant Call/Work/Survey Avoidance",
                message: `Team member displayed behavior during the interaction that gave reason to believe they were blatantly and intentionally avoiding their duties. Please refer to the behavior escalation grid for additional details.

                https://central.ring.com/leadership/cs-behavior-escalation-grid/`
            }
        }
    }, "Training": {
        type: "Training",
        version: "V1",
        options: {
            "callflow1": {
                category: "Topics",
                label: "Confidence",
                message: `Team Member is confident in delivery, providing solid and smooth responses and solutions.`
            },
            "callflow2": {
                category: "Topics",
                label: "Effective Transitions",
                message: `Dead air is minimal (less than 10 seconds unless brought on by the Neighbor) and the Team Member keeps conversation moving with transitional phrases.`
            },
            "callflow3": {
                category: "Topics",
                label: "Takes Ownership and Displays Willingness to Assist",
                message: `Team Member assures the Neighbor that they will help and take responsibility for a resolution.`
            },
            "callflow4": {
                category: "Topics",
                label: "Active Listening Skills",
                message: `Active listening skills are utilized to ensure that there are no conversation collisions/overtalking and the Neighbor does not have to repeat any information.`
            },
            "callflow5": {
                category: "Topics",
                label: "Rapport and Engagement",
                message: `Team Member sounds engaged, interested, energetic, upbeat and conversational when speaking with the Neighbor.   
            The Team Member builds rapport/relationship via inquires such as "how are you today?" or making small talk in order to keep the conversation in motion and engaged.`
            },
            "callflow6": {
                category: "Topics",
                label: "Primary Verification",
                message: `Team Member verified the neighbors account following proper verification.  List out which type of verification was used - Email, Ring App or they gave the neighbor the option.`
            },
            "callflow7": {
                category: "Topics",
                label: "Discovery Questions",
                message: `Team member asked discovery questions to understand the root cause of the neighbors issue before starting to troubleshoot.  List out some of the questions asked.`
            },
            "callflow8": {
                category: "Topics",
                label: "Did we correctly identify the issue the first time",
                message: `Did the Team Member correctly identify the path to troubleshoot the first time or did they have to utilize multiple paths.  If they didn’t identify the issue correctly, what cause the disconnect?`
            },
            "callflow9": {
                category: "Topics",
                label: "Work from Home Environment Concerns",
                message: `Was there any Work from Home Environment Concerns (Music/Animals/Children/Other People in the back ground. Excessive Noise).`
            },
            "callflow10": {
                category: "Topics",
                label: "Excessive Hold Times",
                message: `Hold Times Exceeding 2 Minutes and 30 Seconds with No Check-In with the Neighbor.`
            },
            "softskill1": {
                category: "Soft Skills | Does not apply to review",
                label: "Does not apply",
                message: `Does not apply`
            },
            "neighborimpacting1": {
                category: "Neighbor Impacting | Does not apply to review",
                label: "Does not apply",
                message: `Does not apply`
            },
            "redflag1": {
                category: "Red Flags",
                label: "Does not apply",
                message: `Does not apply`
            }
        }
    },
}
